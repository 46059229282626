import React from 'react';
import classList from 'react-classlist-helper';
import { Parallax } from "react-parallax";

//background image layers
import keyvis_curve_dt from 'assets/images/campaign/keyvis/pb_keyvis__bluecurve__dt.png';
import keyvis_curve_dt_2x from 'assets/images/campaign/keyvis/pb_keyvis__bluecurve__dt@2x.png';
import keyvis_curve_tab from 'assets/images/campaign/keyvis/pb_keyvis__bluecurve__tab.png';
import keyvis_curve_tab_2x from 'assets/images/campaign/keyvis/pb_keyvis__bluecurve__tab@2x.png';
import keyvis_curve_mob from 'assets/images/campaign/keyvis/pb_keyvis__bluecurve__mob.png';
import keyvis_curve_mob_2x from 'assets/images/campaign/keyvis/pb_keyvis__bluecurve__mob@2x.png';

import keyvis_bagel_dt from 'assets/images/campaign/keyvis/pb_keyvis_bagel_dt.png';
import keyvis_bagel_dt_2x from 'assets/images/campaign/keyvis/pb_keyvis_bagel_dt@2x.png';
import keyvis_bagel_tab from 'assets/images/campaign/keyvis/pb_keyvis_bagel_tab.png';
import keyvis_bagel_tab_2x from 'assets/images/campaign/keyvis/pb_keyvis_bagel_tab@2x.png';
import keyvis_bagel_mob from 'assets/images/campaign/keyvis/pb_keyvis_bagel_mobile.png';
import keyvis_bagel_mob_2x from 'assets/images/campaign/keyvis/pb_keyvis_bagel_mobile@2x.png';

import keyvis_bagel_dt_1 from 'assets/images/campaign/keyvis/pb_keyvis_bagel_dt-1.png';
import keyvis_bagel_dt_2 from 'assets/images/campaign/keyvis/pb_keyvis_bagel_dt-2.png';
import keyvis_bagel_dt_1_2x from 'assets/images/campaign/keyvis/pb_keyvis_bagel_dt-1@2x.png';
import keyvis_bagel_dt_2_2x from 'assets/images/campaign/keyvis/pb_keyvis_bagel_dt-2@2x.png';

//foreground text images
import keyvis_text_dt from 'assets/images/campaign/keyvis/pb_keyvis__text__dt.png';
import keyvis_text_dt_2x from 'assets/images/campaign/keyvis/pb_keyvis__text__dt@2x.png';
import keyvis_text_tab from 'assets/images/campaign/keyvis/pb_keyvis__text__tab.png';
import keyvis_text_tab_2x from 'assets/images/campaign/keyvis/pb_keyvis__text__tab@2x.png';
import keyvis_text_mob from 'assets/images/campaign/keyvis/pb_keyvis__text__mob.png';
import keyvis_text_mob_2x from 'assets/images/campaign/keyvis/pb_keyvis__text__mob@2x.png';

import FadeIn from 'components/FadeIn/FadeIn';

import './KeyVisual.scss';


export default () => {

  const classes = {
    "keyvis": true
  }

  let parallaxMargin = 15;
  let parallaxPercentage = 80;
  let offsetParPercentage = 90;
  let strength = 100;
  var isTab = window.matchMedia("only screen and (min-width: 640px)").matches;
  var isDesk = window.matchMedia("only screen and (min-width: 1300px)").matches;

  if (isTab) {
    parallaxMargin = 35;
    strength = 100;
    parallaxPercentage = 100;
    offsetParPercentage = 130;
  }

  if (isDesk) {
    parallaxMargin = 0;
    strength = 100;
    parallaxPercentage = 140;
    offsetParPercentage = 130;
  }

  const offsetPercentage = (percentage) => {
    return percentage - offsetParPercentage;
  }

  return (
    <div className={classList(classes)}>
      <Parallax
        strength={strength}
        renderLayer={percentage => (
          <div className='keyvis-wrapper'>
            <div className='keyvis__bagel-group'>
              <img src={keyvis_bagel_dt_1} srcSet={keyvis_bagel_dt_1_2x + " 2x, " + keyvis_bagel_dt_1 + " 1x"} alt="Bagel with Philadelphia spread"
                className='keyvis__bagel-group__bagel' />
              <img src={keyvis_bagel_dt_2} srcSet={keyvis_bagel_dt_2_2x + " 2x, " + keyvis_bagel_dt_2 + " 1x"} alt="A signed picture of Andi Oliver"
                className='keyvis__bagel-group__portrait' />
            </div>

            <picture>
              <source type="image/png" srcSet={keyvis_bagel_dt_2x + " 2x, " + keyvis_bagel_dt + " 1x"} media="(min-width:992px)" />
              <source type="image/png" srcSet={keyvis_bagel_tab_2x + " 2x, " + keyvis_bagel_tab + " 1x"} media="(min-width:640px)" />
              <img src={keyvis_bagel_mob} srcSet={keyvis_bagel_mob_2x + " 2x, " + keyvis_bagel_mob + " 1x"} alt="Bagel with Philadelphia spread and a signed picture of Andi Oliver"
                className='keyvis__bagel' />
            </picture>
              
            <picture>
              <source type="image/png" srcSet={keyvis_curve_dt_2x + " 2x, " + keyvis_curve_dt + " 1x"} media="(min-width:992px)" />
              <source type="image/png" srcSet={keyvis_curve_tab_2x + " 2x, " + keyvis_curve_tab + " 1x"} media="(min-width:640px)" />
              <img src={keyvis_curve_mob} srcSet={keyvis_curve_mob_2x + " 2x, " + keyvis_curve_mob + " 1x"} alt="Blue curve shape"
                className='keyvis__curve' />
            </picture>


            <div className="keyvis__parallax"
              style={{
                transform: `translate3d(0, ${offsetPercentage((percentage * parallaxPercentage) + parallaxMargin)}px, 0)`,
              }}
            >
              <FadeIn className='keyvis__headline'>
                <picture>
                  <source type="image/png" srcSet={keyvis_text_dt_2x + " 2x, " + keyvis_text_dt + " 1x"} media="(min-width:992px)" />
                  <source type="image/png" srcSet={keyvis_text_tab_2x + " 2x, " + keyvis_text_tab + " 1x"} media="(min-width:640px)" />
                  <img src={keyvis_text_mob} srcSet={keyvis_text_mob_2x + " 2x, " + keyvis_text_mob + " 1x"} alt="Philadelphia brunch: Win a VIP brunch, hosted by Andi Oliver" />
                </picture>
              </FadeIn>
            </div>
          </div>

        )}
      />

    </div>

  )
}