import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import classList from 'react-classlist-helper';
import FocusTrap from 'focus-trap-react';
import useSettingsContext from 'contexts/useSettingsContext';
import accountService from 'services/accountService';
import Link from 'components/Link/Link';
//import { ReactComponent as Logo } from 'assets/images/themes/cadbury/logo.svg';
import Logo from 'assets/images/themes/philadelphia/logo@2x.png';

import analyticsService from 'services/analyticsService';

import './Header.scss';

let lastScroll = 0;
let direction = 0;
let menuOpen = false;
let ticking = false;

export default function (props) {
  const settings = useSettingsContext();
  const history = useHistory();
  const [hide, setHide] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const menuStateInput = useRef();

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (!navOpen) {
      document.getElementsByTagName('body')[0].removeAttribute("style");
    } else {
      document.getElementsByTagName('body')[0].style.overflow = "hidden";
    }
  }, [navOpen]);

  useEffect(() => {
    if (menuStateInput && menuStateInput.current) {
      menuStateInput.current.addEventListener("keyup", onKeyUp);
      return () => menuStateInput.current.removeEventListener("keyup", onKeyUp);
    }
  }, []);

  const onScroll = () => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handleScroll();
        ticking = false;
      });
      ticking = true;
    }
  }

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  }

  const handleScroll = async () => {
    let scroll = getScroll();
    direction = (scroll > lastScroll) ? 1 : -1;

    if (menuStateInput.current) {
      menuOpen = await getMenuState();
    }

    setHide(direction === 1 && !menuOpen && scroll > 150);
    lastScroll = scroll;
  }

  const getMenuState = async () => {
    return menuStateInput.current.checked;
  }

  const getScroll = () => {
    return window.scrollY || window.pageYOffset;
  }

  const handleClick = () => {
    menuStateInput.current.click();
  }

  const onInputChange = (e) => {
    setNavOpen(e.currentTarget.checked);

    if (e.currentTarget.checked) {
      analyticsService.logEvent("siteNavigationLink", "NavigationMenuButton", {
        action: "OpenNavigationMenu",
        label: "open menu"
      });
    } else {
      analyticsService.logEvent("siteNavigationLink", "NavigationMenuButton", {
        action: "CloseNavigationMenu",
        label: "close menu"
      });
    }
  }


  const classMap = {
    "header": true,
    "header--hide": hide,
    "header--navopen": navOpen,
    [props.className]: !!props.className,
  };

  return (
    <header className={classList(classMap)}>

      {
        (props.is500 !== undefined && props.is500) ? (
          <Link className="header__logo" to="/">
            <img src={Logo} className="header__svg" alt="Philadelphia logo" />
          </Link>
        ) : (
          <Link route className="header__logo" to="/">
            <img src={Logo} className="header__svg" alt="Philadelphia logo" />
          </Link>
        )
      }

      {props.enableMenu &&

        <FocusTrap active={navOpen}>
          <nav className="header__nav">
            <div className="header__menuToggle">
              <input type="checkbox" ref={menuStateInput} onChange={onInputChange} />
              <span className='header__menuToggle__focus'></span>
              <span className='header__menuToggle__span'></span>
              <span className='header__menuToggle__span'></span>
              <span className='header__menuToggle__span'></span>
              <div className="header__menu">
                <ul className="header__menu__nav">
                  <li><Link
                    analytics={{ evnt: "siteNavigationLink", category: "HomeLink", action: "OpenHomeLink" }}
                    to="/"
                    route
                    title="Home"
                    className="header__link"
                    cancelFocus={!navOpen}
                    onClick={handleClick} >
                    Home
                  </Link></li>

                  <li><Link
                    analytics={{ evnt: "siteNavigationLink", category: "FAQsLink", action: "OpenFAQsLink" }}
                    to="/faqs"
                    route
                    title="Home"
                    className="header__link"
                    cancelFocus={!navOpen}
                    onClick={handleClick} >
                    FAQ<span className="lowercase">s</span>
                  </Link></li>

                  <li><Link
                    analytics={{ evnt: "siteNavigationLink", category: "TandCsLink", action: "OpenTandCsLink" }}
                    to="/terms-and-conditions"
                    route
                    title="Home"
                    className="header__link"
                    cancelFocus={!navOpen}
                    onClick={handleClick} >
                    T&amp;C<span className="lowercase">s</span>
                  </Link></li>
                </ul>

                <div className="header__menu__footer">
                  <Link
                    analytics={{ evnt: "siteFooterLink", category: "PrivacyPolicyLink", action: "OpenPrivacyPolicy" }}
                    cancelFocus={!navOpen} className="type-hpara"
                    href="https://privacy.mondelezinternational.com/eu/en-gb/privacy-notice/" onClick={handleClick}
                    target="_blank" >
                    Privacy policy
                  </Link>
                </div>

              </div>
            </div>
            <div className="header__overlay" onClick={handleClick}></div>
          </nav>
        </FocusTrap>

      }
    </header>
  );
}