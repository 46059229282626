import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';


export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#','').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index && 
        index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key =  `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Philadelphia - Win a VIP brunch</title>
    </Helmet>
    <section className="faqs">
      <div className='layout layout-general layout-wrapper'>
        <div className="layout-container layout-container--medium layout-container--center ">
          <h1 className="type-h1">FAQ<span className="lowercase">s</span></h1>
        { 
        <>
         <div className="faqs__section">
        
            
              <h2 className="type-h3">General promotional questions</h2>
              {
                renderFaqs(1, [{
                  question: "1. What is the Philadelphia Brunch promotion?",
                  answer: `
                    <p>
                      Philadelphia's Brunch campaign is a promotion that gives you the chance to win a VIP brunch experience for yourself and five guests, hosted by Andi Oliver! There are also 55 Brunch Home bundles available to be won. With every pack purchase, you can also download a free Philly Brunch recipe PDF by heading to the campaign website. For a full breakdown of the prizes please see the T&Cs page <a href="/terms-and-conditions">here</a>.
                    </p>`
                  },{
                  question: "2. Who is the promotion open to?",
                  answer: `
                  <p>
                    The promotion is open to anyone over 18 who lives in the UK, including Channel Islands and Isle of Man, and the Republic of Ireland.
                  </p>
                `},{
                  question: "3.	How can I enter/ How does it work?",
                  answer: `
                  <p>
                    To enter for the UK, simply buy any participating Philadelphia product, head to <a href="http://philadelphia-brunch.com" rel="noopener noreferrer" target="_blank">philadelphia-brunch.com</a>, select your region, enter the barcode and batch code, and enter your details. The barcode and batch code can be found on the back of your participating product. If you'd like to review the full terms and conditions and product details please click <a href="/terms-and-conditions">here</a>.
                  </p>
                  <p>
                    To enter for the Republic of Ireland, simply head to <a href="http://philadelphia-brunch.com" rel="noopener noreferrer" target="_blank">philadelphia-brunch.com</a> select your region, and enter your details including your Eircode.
                  </p>
                  <p>
                    All entries will be entered into respective prize draws for the chance to win a VIP brunch experience and a Brunch Home bundle. The prize draw will take place within 5 working days after the promotion ends. The promotion ends on the 31st August 2024.
                  </p>                  
                `},{
                  question: "4. Which products are included in the promotion?",
                  answer: `
                  <p>
                    All Philadelphia products are included within the Philadelphia Brunch campaign. For a full list of participating products please visit the T&Cs page <a href="/terms-and-conditions#products-list">here</a>.
                  </p>
                `},{
                  question: "5. When can I enter?",
                  answer: `
                  <p>
                    The promotion runs from 10:00 BST on 01/07/24 to 17:00 BST on 31/08/24.
                  </p>
                `},{
                  question: "6. Do I need to keep my receipt for the participating product until the end of the promotion?",
                  answer: `
                  <p>
                    Yes, if you live in England, Scotland, Wales, Northern Ireland, Channel Islands and Isle of Man, proof of purchase may be required to verify your claim - so don't throw it away.
                  </p>
                `},{
                  question: "7.	Do I need internet access?",
                  answer: `
                  <p>
                    You will need internet access to enter the promotion.
                  </p>
                  <p>
                    You also need a valid email address in order to claim a prize.
                  </p>
                `}
                ])
              }
            </div>
            <div className="faqs__section">
              <h2 className="type-h3">Prize details, claiming and redemption</h2>
              {
                renderFaqs(2, [{
                  question: "8.	What prizes are available?",
                  answer: `
                  <p>
                    There is 1 VIP Brunch Out Experience up for grabs for the winner and 5 guests, including travel, an overnight stay in a 5* hotel in central London, a VIP Brunch experience at a central London location accompanied by Andi Oliver, and spending money. There are also 55 Brunch at Home bundles available to be won, worth £250. Brunch bundles include a glassware and crockery hamper including porcelain plates and bowls and China glassware teacups and saucers; a premium breakfast food hamper including tea, coffee, pastries, baked bread, cereal, granola and the ingredients to make an English breakfast; and a Philadelphia selection pack including Philadelphia Original, Philadelphia Garlic & Herb, Philadelphia Salmon, and Philadelphia Chive.
                  </p>
                `},{
                  question: "9.	How will I know if I have won?",
                  answer: `
                  <p>
                    The Prize Draw will be conducted within 5 working days of the end of the promotion. Provisional Winners will be contacted via the email address provided on entry within 5 working days of Winner Selection and will be asked to provide evidence of identity and eligibility in line with the campaign <a href="/terms-and-conditions">T&Cs</a>.
                  </p>
                `},{
                  question: "10.	Is there a deadline for me to claim my Level 2/Level 3 prize?",
                  answer: `
                  <p>
                    Yes, you must respond to the  initial contact within 14 days before a reserve winner is drawn in place, in line with the campaign <a href="/terms-and-conditions">T&Cs</a>.
                  </p>
                `}
                ])
              }
            </div>          
            <div className="faqs__section">
              <h2 className="type-h3">On pack codes and entry mechanics</h2>
              {
                renderFaqs(3, [{
                  question: "11.	What codes do I need to enter to participate?",
                  answer: `
                  <p>
                    If you live in England, Scotland, Wales or Northern Ireland you must enter both the barcode and batch code as part of your entry, which can be found on the back of your Philadelphia product. Entrants in ROI do not need a code to enter, in line with the NPN process.

                  </p>
                `},{
                  question: "12.	I can't read my code",
                  answer: `
                  <p>
                    In some circumstances the batch code can be illegible or difficult to read. If you are having difficulties reading your code please contact our customer service team on <a href="tel:+440800818181">0800 818 181</a> or at <a href="https://contactus.mdlzapps.com/philadelphiacouk/contact/en-UK/" rel="noopener noreferrer" target="_blank">https://contactus.mdlzapps.com/philadelphiacouk/contact/en-UK/</a> who will be able to assist you.
                  </p>
                `},{
                  question: "13. My code isn't working, am I entering the right code?",
                  answer: `
                  <p>
                    It can be confusing as products often feature many codes on the back of the pack for production reasons. Please double check you are entering the correct code within the required form field.
                  </p>
                  <p>
                    The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is the alpha-numerical code found on the back of your pack located directly above the “Best Before” date.
                  </p>
                `},
                ,{
                  question: "14.	I've already entered my code; can I enter it again for another chance to win?",
                  answer: `
                  <p>
                    Each entry needs to be accompanied by a new purchase, as the same code cannot be entered multiple times. 
                  </p>
                `},
                ,{
                  question: "15.	My code has been declined online and I get an error message, what can I do?",
                  answer: `
                  <p><strong>If the error message is <i>‘This code is not recognised'</i></strong></p>
                  <p>
                    Please check that you're trying to enter the right code. Products often feature many codes on the back of the pack for production reasons. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is the digit alpha-numerical code found on the back of your pack located directly above the “Best Before” date.
                  </p>
                  <p><strong>If the error message is <i>‘This code has already been used'</i></strong></p>
                  <p>
                    Remember, users are limited to one entry per person per email address per day. Only your first entry each day will be counted. Please note that there is a maximum of 20 entries per person during the entire Promotion Period.
                  </p>
                `},
                ,{
                  question: "16.	I entered the wrong email address. What should I do?",
                  answer: `
                  <p>
                    If you have entered an incorrect email address please contact our customer service team on please contact our customer service team on <a href="tel:+440800818181">0800 818 181</a> or at <a href="https://contactus.mdlzapps.com/philadelphiacouk/contact/en-UK/" rel="noopener noreferrer" target="_blank">https://contactus.mdlzapps.com/philadelphiacouk/contact/en-UK/</a> who will be able to assist you.
                  </p>
                `}
                ])
              }
            </div>          
        </>
           
          }  
        </div>

      </div>
    </section>
  </>;
}