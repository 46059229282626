import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';

import ValidationErrorCollection from 'validators/ValidationErrorCollection';

import CodeEntry from './CodeEntry';
import PrizeBlock from 'components/Prize/PrizeBlock';
import PrizeBlockDouble from 'components/Prize/PrizeBlockDouble';
import Step from 'components/Step/Step';
import Link from 'components/Link/Link';
import Button from 'components/Button/Button';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';


import KeyVisual from './KeyVisual';

import How1 from 'assets/images/campaign/how-1.png';
import How1_2x from 'assets/images/campaign/how-1@2x.png';
import How2 from 'assets/images/campaign/how-2.png';
import How2_2x from 'assets/images/campaign/how-2@2x.png';
import How3 from 'assets/images/campaign/how-3.png';
import How3_2x from 'assets/images/campaign/how-3@2x.png';
import How4 from 'assets/images/campaign/how-4.png';
import How4_2x from 'assets/images/campaign/how-4@2x.png';
import How5 from 'assets/images/campaign/how-5.png';
import How5_2x from 'assets/images/campaign/how-5@2x.png';

//PRIZES
import recipeCardsCombinedMobile from 'assets/images/campaign/prizes/recipeCardsCombinedMobile1.png';
import recipeCardsCombinedMobile2x from 'assets/images/campaign/prizes/recipeCardsCombinedMobile1@2x.png';
import recipeCardsCombinedDesktop from 'assets/images/campaign/prizes/recipeCardsCombinedDesktop.png';
import recipeCardsCombinedDesktop2x from 'assets/images/campaign/prizes/recipeCardsCombinedDesktop@2x.png';

import vipIcon from 'assets/images/campaign/prizes/vipDesktop.png';
import vipIcon2x from 'assets/images/campaign/prizes/vipDesktop@2x.png';
import basketIcon from 'assets/images/campaign/prizes/basket.png';
import basketIcon2x from 'assets/images/campaign/prizes/basket@2x.png';

import analyticsService from 'services/analyticsService';
import CountrySelect from './CountrySelect';

import './Home.scss';
import CountryCtaModal from './CountryCtaModal';

export default () => {
  const [settings, setSettings] = React.useContext(SettingsContext);
  let settingsState = useSettingsContext();
  let history = useHistory();
  let compState = settings.competitionStatus.openState;
  let compPhaseId = settings.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let competitionIsNotStarted = compState === "NotStarted";
  let competitionIsClosed = compState === "Closed";
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [showCountryModal, setShowCountryModal] = useState(!settings.country);
  const [showCountryCtaModal, setShowCountryCtaModal] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  const handleCountrySelected = (data) => {
    sessionStorage && sessionStorage.setItem('philadelphiaBrunch.country', data.country);
    settingsState.setCountry(data.country);
    setShowCountryModal(false);
  }

  const handleEnterNow = async () => {
    if (settings.country !== "ROI") {
      analyticsService.logEvent("enterCompetition", "ProofOfPurchase", {
        action: "OpenProofOfPurchaseModal",
        label: "open modal",
        value: "Open code entry modal"
      });

      setShowEntryModal(true);
    } else {
      analyticsService.logEvent("enterCompetition", "NoPurchaseNecessary", {
        action: "OpenRegistration"
      });      
      history.push("/registration");
    }
  }

  const renderSteps = (steps) => {
    return steps.map((step, index) => {
      return (
        <Step key={step.number} {...step} />
      )
    })
  }

  const renderEntryCta = (title) => {
    if (!settings.country) return null;

    return <Button
      wide
      type="submit"
      title={title ? title : "Enter Now"}
      onClick={handleEnterNow}
    />
  }

  const renderIntroMessage = (compState) => {
    switch (compState) {
      case 'Open':
        return <>
            <div className="home__intro">
              <h2 className='type-h2'>Win a VIP brunch for you and your friends <span>hosted by Andi Oliver</span></h2>
              
              <div className="home__intro-body">
                <p>
                  Ever wanted to treat your friends to the once-in-a-lifetime brunch experience you know they deserve? Well now you can, with Philly!
                </p>
              </div>
              <div className="home__cta">
                {renderEntryCta("Enter now")}
              </div>
            </div>     
        </>

      case 'NotStarted':
        return <>
          <div className="home__intro home__intro--slim">
            <h2 className='type-h2'>It's almost time to… <br/> WIN a VIP brunch for you and your friends <span>hosted by Andi Oliver</span></h2>
            <div className='home__intro-body'>
              <p>
                <span>You're a bit early!</span>
              </p>
              <p>
                Come back on 1st July, when you'll have the chance to win a truly incredible VIP brunch experience, including a first class trip to London, luxury overnight stay, and a sensational brunch for you and 5 friends hosted by the one and only <span>Andi Oliver</span>.
              </p>
              <p>
                We'll also be giving away Philly hampers, complete with everything you need to host a special brunch for your friends, and a FREE digital recipe book with every pack.
              </p>
              <p>
                While you're waiting, why not head to the website to get some delicious brunch-spiration?
              </p>
            </div>
          </div>

          <div className="home__cta">
            <Link 
            analytics={{evnt: "siteLink", category: "NotStartedPhaseIntroduction", action: "OpenPhiladelphiaUKWebsite"}}
            to="https://www.philadelphia.co.uk/recipes/"  lozenge title="Inspire Me" >Inspire Me</Link>
            
          </div>

        </>

      case 'Closed':
        return <>
          <div className="home__intro home__intro--smallText">

            <h2 className='type-h3'>The competition is now closed</h2>
            <div className="home__intro-body">
              <p>
                …but there's more brunch-inspired greatness going on at the Philly website. Go take a look now.
              </p>
            </div>
          </div>
          <div className="home__cta">
          <Link 
            analytics={{evnt: "siteLink", category: "ClosedPhaseIntroduction", action: "OpenPhiladelphiaUKWebsite"}}
            to="https://www.philadelphia.co.uk/recipes/"  lozenge title="Brunch Inspo" >Brunch Inspo</Link>
          
          </div>
        </>
    }
  }

  return (
    <div className="layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home | Philadelphia - Win a VIP brunch</title>
      </Helmet>
      {competitionIsOpen &&
        <>
          <CodeEntry
            show={showEntryModal}
            onDismiss={() => setShowEntryModal(false)}
            onEntry={() => history.push("/registration") } 
          />

          <CountrySelect
            show={showCountryModal}
            onCountrySelected={handleCountrySelected}
          />
        </>
      }

      {
        !competitionIsOpen && 
          <CountryCtaModal
            show={showCountryCtaModal}
            onDismiss={() => setShowCountryCtaModal(false)}
            competitionIsClosed={competitionIsClosed}
          /> 
      }

      <h1 className="visually-hidden">Philadelphia Original - Win a VIP Brunch</h1>

      <section className="layout-wrapper home__banner">
        <div className="home__banner-container">
          <div className="home__banner-content">
            <KeyVisual />
          </div>
        </div>
      </section>

      
        <section className="layout-wrapper home__entrybar relative">
          <div className="layout-container layout-container--center">

            {renderIntroMessage(compState)}
            
          </div>
        </section>

        
        {(competitionIsOpen) &&
          
          <section className="layout-wrapper home__prize-block-section bg-white">
            <div className="layout-container layout-container--center">
              <h2 className="type-h1 type-h1--blue">Prizes</h2>

            

              <div className="home__prize-blocks ">  
                    <PrizeBlock
                      image={vipIcon}
                      image2x={vipIcon2x}
                      title="VIP brunch for you and your friends <span>hosted by Andi Oliver</span>"
                      description={`<p>We're giving one lucky winner, plus five friends, the chance to be whisked off to London for a brunch they'll never forget… curated and hosted by the one and only Andi Oliver! In true VIP style, everything will be taken care of – from first-class train travel, to a luxury hotel stay and extra spending money.
                      </p>`}
                      alt="Philadelphia Brunch: Win a VIP brunch hosted by Andi Oliver"
                    />

                    <PrizeBlock
                      image={basketIcon}
                      image2x={basketIcon2x}
                      title="Plus Brunch Hampers <br/>to be won"                  
                      description={`<p>We've also got lots of fabulous Philly brunch hampers to be won, packed with everything you need to host your own VIP brunch, including your most delicious brunch companion - Philly!</p>`}
                      alt="Philadelphia Brunch: Win a brunch hamper"
                   />                
              </div>

              <div className="home__prize-blocks ">
                  <PrizeBlockDouble
                    imageMobile={recipeCardsCombinedMobile}
                    imageMobile2x={recipeCardsCombinedMobile2x}
                    imageDesktop={recipeCardsCombinedDesktop}
                    imageDesktop2x={recipeCardsCombinedDesktop2x}
                    imageAlt="Philadelphia Brunch: mmm text"
                    title="Recipes"
                  />
              </div>
            </div>
          </section>

        }

      {
        (competitionIsOpen) &&
        <section className="layout-wrapper home__howto" id="how-to-play">
          <div className="layout-container layout-container--center ">
            <h2 className="type-h1">How does it work?</h2>
            <div className="home__steps">
            {
                
              (settings.country === "ROI") ?
                (
                  renderSteps([
                    { number: 1, title: "Download your Philly recipe PDF.", image: How3, image2x: How3_2x, alt: "Philadelphia brunch: Download a recipe PDF" },
                    { number: 2, title: "Fill in your details.", image: How4, image2x: How4_2x, alt: "Form Graphic." },
                    { number: 3, title: "You're now entered into the prize draw.", image: How5, image2x: How5_2x, alt: "Philapdelphia brunch: Win a VIP Brunch & more.", description: "The prize draw will take place at the end of the competition on 31/08/2024" },
                  ])
                ) : (
                  renderSteps([
                    { number: 1, title: "Buy any participating ", image: How1, image2x: How1_2x, alt: "Philadelphia products", descriptionLink: <><a href="/terms-and-conditions#products-list" rel="noopener noreferrer" target='_blank'>Philly pack.</a></> },
                    { number: 2, title: "Enter the barcode and batch code of your product.", image: How2, image2x: How2_2x, alt: "A barcode and batch code from a Philadelphia pack" },
                    { number: 3, title: "Download your Philly recipe PDF.", image: How3, image2x: How3_2x, alt: "Philadelphia brunch: Download a recipe PDF" },
                    { number: 4, title: "Fill in your details.", image: How4, image2x: How4_2x, alt: "Form Graphic." },
                    { number: 5, title: "You're now entered into the prize draw.", image: How5, image2x: How5_2x, alt: "Philapdelphia brunch: Win a VIP Brunch & more.", description: "The prize draw will take place at the end of the competition on 31/08/2024" }
                  ])
                )
    
            }
            
            </div>
            <div className="home__cta">
              {renderEntryCta()}
            </div>
          </div>
        </section>
      }

      {
        (competitionIsNotStarted || competitionIsOpen) &&
        <SummaryTsandCs  />
      }

    </div >
  );
}