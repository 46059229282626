import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import analyticsService from 'services/analyticsService';
import useEffectOnce from 'hooks/useEffectOnce';

import NpsBanner from 'components/NpsBanner/NpsBanner';
import timer from 'utils/timer';

import './Thankyou.scss';

import keyvis_bagel_dt from 'assets/images/campaign/thankyou/pb_thankyou_dt.png';
import keyvis_bagel_dt_2x from 'assets/images/campaign/thankyou/pb_thankyou_dt@2x.png';
import keyvis_bagel_tab from 'assets/images/campaign/thankyou/pb_thankyou_tab.png';
import keyvis_bagel_tab_2x from 'assets/images/campaign/thankyou/pb_thankyou_tab@2x.png';
import keyvis_bagel_mob from 'assets/images/campaign/thankyou/pb_thankyou_mob.png';
import keyvis_bagel_mob_2x from 'assets/images/campaign/thankyou/pb_thankyou_mob@2x.png';


export default () => {
  let { entryPublicId } = useParams();
  let [settings, setSettings] = React.useContext(SettingsContext);
  let compState = settings.competitionStatus.openState;
  let competitionIsOpen = compState === "Open";
  let competitionEntryContext = useCompetitionEntryContext();
  //let [entry, setEntry] = useState(competitionEntryContext.result);
  let [entry, setEntry] = useState("");
  let [showNps, setShowNps] = useState(null);


  useEffect(() => {
    async function fetchData() {
      await competitionEntryService.getCompetitionEntry(entryPublicId)
        .then((result) => {
          if (result) {            
            setEntry(result);
            if(result.nps != null) {              
              setShowNps(false);
            } else {              
              timer.delay(2500).then(() => setShowNps(true));
            }    
            
            
            var email = competitionEntryContext.email;
            //Had to go here, as not triggered on last page
            if (email != null) {
              // Save email to DataLayer - GTM uses this to pass to Lytics (Only if the user has accepted targeting cookies)
              console.log("not null");
              analyticsService.logEvent("forms", "Forms", {
                action: "EmailCapture",
                email
              });

              analyticsService.logEvent("forms", "Forms", {
                action: "RegistrationSuccess"
              });

              if (entry.hasWon) {
                analyticsService.logEvent('event', 'Prize', {
                  action: entry.prizePublicId
                });
              }
            }
          }
        })
        .catch(err => console.log(err));
    }

    if (entryPublicId && !entry) {
      fetchData();
    }
  }, [entry]);


  // useEffectOnce(() => {
    
  //   timer.delay(2500).then(() => setShowNps(true));
  // });

  // if (competitionIsOpen && ((entry !== null && !entry.entryPublicId) || !entryPublicId)) {
  //   return (
  //     <Redirect to="/" />
  //   )
  // }

  const renderNpsBanner = (entryPublicId) => {
    if (!entryPublicId) return null;
    return <NpsBanner onClose={() => setShowNps(false)} competitionEntryPublicId={entryPublicId} show={showNps} />
  }

  return (
    <>
      {
        (entryPublicId && !entry) ? (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Error | Philadelphia - Win a VIP brunch</title>
            </Helmet>
            <section className='thankyou'>
              <div className="layout-general layout-wrapper layout-general--full">
                <div className="layout-container layout-container--center layout-general--slim">
                  <h1 className="type-h1 type-h1--xl">Error!</h1>
                  <p className="thankyou__para">
                    Sorry, something went wrong.
                  </p>
                  <Button type="button" title="Back to homepage" href="/" className="mt-20 button--blue" />
                </div>
              </div>
            </section>
          </>
        ) : (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Thank you | Philadelphia - Win a VIP brunch</title>
            </Helmet>

              <section className="layout-general layout-general--full thankyou bg-white">

                <div className='thankyou__bg'>
                  <picture>
                    <source type="image/png" srcSet={keyvis_bagel_dt_2x + " 2x, " + keyvis_bagel_dt + " 1x"} media="(min-width:992px)" />
                    <source type="image/png" srcSet={keyvis_bagel_tab_2x + " 2x, " + keyvis_bagel_tab + " 1x"} media="(min-width:640px)" />
                    <img src={keyvis_bagel_mob} srcSet={keyvis_bagel_mob_2x + " 2x, " + keyvis_bagel_mob + " 1x"} alt="" />
                  </picture>
                </div>
                <div className="layout-container layout-container--center layout-container--slim">
                  <h1 className="type-h1 type-h1--xl">Thank You!</h1>
                  <p className="thankyou__para">
                    You’ve been entered into the draw for the chance to win a VIP brunch experience with Andi Oliver. <span>The draw will take place on 31/08/2024.</span>
                  </p>
                  <p className="thankyou__para">
                    We’re crossing our fingers for you!
                  </p>
                  <p className="thankyou__para ">
                    In the meantime, why not head to the Philly website for some brunch inspiration?  
                  </p>
                  <Link 
                    analytics={{evnt: "siteLink", category: "ThankYouPage", action: "OpenPhiladelphiaWebsite", value: "Open Philadelphia website link"}}
                    to="https://philadelphia.co.uk/" target="_blank" lozenge title="Philadelphia.co.uk" >Philadelphia.co.uk</Link>
                </div>
                {renderNpsBanner(entryPublicId)}
              </section>
          </>
        )

      }

    </>

  );
}
