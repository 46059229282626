import React, { useState, useEffect } from 'react';
import Modal from 'components/Modal/Modal';
import Link from 'components/Link/Link';

export default (props) => {

    let categoryPhase = props.competitionIsClosed ? "ClosedPhaseIntroduction" : "NotStartedPhaseIntroduction";
    console.log("categoryPhase: " ,categoryPhase);
  if (props.show) {
    return (
      <Modal panel className="modal"
      onDismiss={props.onDismiss}>
        <p className="country-select__intro" tabIndex="0">
         Please tell us where you live:
        </p>
        <div className="country-select__inputs">
            <Link 
                analytics={{evnt: "siteLink", category: categoryPhase, action: "OpenCadburyFCUKWebsite"}}
                to="https://www.cadbury.co.uk/cadburyfc/"  lozenge title="Visit Cadbury FC" >United Kingdom</Link>
            <Link 
                analytics={{evnt: "siteLink", category: categoryPhase, action: "OpenCadburyFCROIWebsite"}}
                to="https://www.cadbury.ie/cadburyfc/" lozenge title="Visit Cadbury FC" >Republic of Ireland</Link>
        </div>
      </Modal>
    )
  } else {
    return null;
  }
}