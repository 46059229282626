import ValidationErrorCollection from './ValidationErrorCollection';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const ALPHA_REGEX = /^[A-Za-z]+$/;
//const NAME_REGEX = /^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+)*$/;
const NAME_REGEX2 = /^(((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘)))+( (((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ])|((\w|'|´|’|‘)+[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ]+(\w|'|´|’|‘))))*$/;
const ADDRESS_REGEX =/^[a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+( [a-zA-ZÀ-ÖØ-ŷ-öø-ÿ-0-9'´’‘#,\.]+)*$/;
const NUMBERS_REGEX = /^(?:[+\d].*\d|\d)$/;
const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50}$/;
const EIRCODE_REGEX = /^([AC-FHKNPRTV-Y]{1}[0-9]{2}|D6W)[ ]?[0-9AC-FHKNPRTV-Y]{4}$/i;
const POSTCODE_REGEX = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/i;
const GENERIC_POSTCODE_REGEX = /^[a-zA-Z0-9]+( [a-zA-Z-0-9]+)*$/;

function validate(command, settings) {
  let messages = new ValidationErrorCollection();

  if (!command) {
    throw new Error('command cannot be null'); 
  }

  required('firstName', 'Please enter your first name');
  required('email', 'Please enter your email address');
  required('emailConfirm', 'Please enter your email confirmation');
  required('country', 'Please select a country');
  required('terms', 'Please confirm you agree to our terms & conditions');
  required('legalAge', 'Please confirm you are over 18');
  required('recaptcha2', 'Please confirm you are not a robot');
  required('postcode', 'Please enter your postcode');

  if (command.country === "ROI") {
    //required('address1', 'Please enter the first line of your address');
    required('postcode', 'Please enter your eircode');

    // if (command.address1!== '' && !ADDRESS_REGEX.test(command.address1)) {      
    //   messages.addError('address1', 'Please enter a valid address');      
    // }

    // if (command.address2!== '' && !ADDRESS_REGEX.test(command.address2)) {      
    //   messages.addError('address2', 'Please enter a valid address');
    // }

    if(command.postcode && !EIRCODE_REGEX.test(command.postcode)){
      messages.addError('postcode', 'Please enter a valid Eircode');
    }
  }

  if(command.country !== "ROI") {
    if(command.postcode && !POSTCODE_REGEX.test(command.postcode)) {
      messages.addError('postcode', 'Please enter a valid postcode');
    }
  }

  if (command.firstName && !NAME_REGEX2.test(command.firstName)) {
    messages.addError('firstName', 'Please enter a valid first name');
  }

  if (command.email && !EMAIL_REGEX.test(command.email)) {
    messages.addError('email', 'Please enter a valid email address');
  }

  if (command.email && command.emailConfirm && command.email.toLowerCase() !== command.emailConfirm.toLowerCase()) {
    messages.addError('emailConfirm', 'Please confirm your email address');
  }

  if (command.dob && !command.dob.isValid) {
    messages.addError('dob', command.dob.error || "Please enter a valid date of birth");
  }

  return messages;
  
  function required(prop, error) {
    if (!command[prop]) {
      messages.addError(prop, error);
    }
  }
}

export default {
  validate
};
