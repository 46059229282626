import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';

import Home from './Home/Home';
import RegistrationRouteGuard from './Forms/RegistrationRouteGuard';
import TermsAndConditionsRouteGuard from './Policies/TermsAndConditionsRouteGuard';
import CookiePolicy from './Policies/Cookies';
import Thankyou from './Thankyou/Thankyou';
import FourOhFour from './Errors/FourOhFour';
import FAQsRouteGuard from './FAQs/FAQsRouteGuard';


export default (props) => {
  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route 
        exact 
        key="home"
        path="/">
        <Home />
      </Route>
       <Route 
        exact 
        key="registration"
        path="/registration">
        <RegistrationRouteGuard />
      </Route>
      <Route 
        exact 
        key="thankyou"
        path="/thankyou/:entryPublicId">
        <Thankyou />
      </Route>
      {/* <Route 
        exact 
        key="thankyou"
        path="/thankyou">
        <Thankyou />
      </Route> */}
      <Route 
        exact 
        key="faqs"
        path="/faqs">
      <FAQsRouteGuard />
      </Route>
      <Route 
        exact 
        key="terms-and-conditions"
        path="/terms-and-conditions">
        <TermsAndConditionsRouteGuard />
      </Route>
      <Redirect exact from="/cookies" to="/cookie-policy" />
      <Route 
        exact 
        key="cookie-policy"
        path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route>
        <FourOhFour />
      </Route>
    </AnimatedSwitch>
  );
}
