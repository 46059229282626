import React from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlockDouble.scss';

const PrizeBlockDouble = (props) => {

  const mainClassMap = {
    "prize-block-double": true,
  };


  return (
    <section className={classList(mainClassMap)}>
      <div className='prize-block-double__image'>
        <picture>
          <source type="image/png" media="(min-width: 768px)" srcSet={props.imageDesktop2x + " 2x, " + props.imageDesktop + " 1x"} />
          <img src={props.imageMobile} srcSet={props.imageMobile2x + " 2x, " + props.imageMobile + " 1x"} alt={props.imageTextAlt} className='prize-block-double__image-top' />
        </picture>
      </div>

      <div className='prize-block-double__info'>
        <h3 className='prize-block-double__title type-h1'>{props.title}</h3>
        <p className='prize-block-double__info-copy'>What better way to start the day than with good friends + Philly!?
        </p>
        <p className='prize-block-double__info-copy'>Whether you’re getting stuck in to a small brunch with your besties, or looking for sweet & savoury serves that are perfect for sharing at a bigger brunch do, Philly’s got you (and your bagels) covered.
        </p>
        <p className='prize-block-double__info-copy'>
        Enter your pack code to download our digital recipe book, and discover irresistible inspo for your brunch creations, including an extra special recipe crafted especially for Philly by the fabulous <strong>Andi Oliver</strong>. Ooh!
        </p>
      </div>
    </section>
  )
}

export default PrizeBlockDouble;
