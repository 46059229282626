import React, { useRef, useState, useEffect } from 'react';
import classList from 'react-classlist-helper';
import { useHistory } from 'react-router-dom';

import usePageViewLogging from 'hooks/usePageViewLogging';
import useReloadOnVersionUpdate from 'hooks/useReloadOnVersionUpdate';
import useEffectOnce from 'hooks/useEffectOnce';
import usePicturefill from 'hooks/usePicturefill';
import useSettingsContext from 'contexts/useSettingsContext';
import Routes from 'containers/Routes';
import ErrorBoundary from 'containers/Errors/ErrorBoundary';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import SiteLoader from 'components/SiteLoader/SiteLoader';

import { CompetitionEntryProvider } from 'contexts/CompetitionEntryContext';
import settingsContext from 'contexts/SettingsContext';
import settingsService from 'services/settingsService';
import accountService from 'services/accountService';
import timer from 'utils/timer';


import '../styles/base.scss';

const Wrapper = (props) => {
  const settings = useSettingsContext();
  const [routing, setRouting] = useState(false);
  const [simpleTemplate, setSimpleTemplate] = useState(false);
  const mainRef = useRef();
  let history = useHistory();

  history.listen(() => {
    async function routeListener() {
      timer.delay(100).then(() => window.scrollTo(0, 0));
      setRouting(true);
      await timer.delay(1000);
      setRouting(false);
    }

    routeListener();
  });

  useEffect(() => {
    let currpathname = history.location.pathname.toLowerCase();

    if(currpathname.indexOf("registration") > -1 || 
      currpathname.indexOf("claim") > -1 || 
      currpathname.indexOf("forgotten-password") > -1 || 
      currpathname.indexOf("login") > -1 ||
      currpathname.indexOf("reset") > -1 ){
      setSimpleTemplate(true);
    } else {
      setSimpleTemplate(false);
    }

  }, [history.location]);

  useEffect(() => {
    const onResize = e => {
      settings.setContentHeight(mainRef.current && mainRef.current.offsetHeight);
      settings.setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", onResize);
    setTimeout(onResize, 1000);

    return () => window.removeEventListener("resize", onResize);
  }, [routing]);

  const classMap = {
    "routing": routing,
    "simple-template": simpleTemplate
  };

  return (
    <>
      <main className={classList(classMap)} ref={mainRef}>
        {props.children}
      </main>
      { !routing &&
        <Footer showTerms={props.showTerms} />
      }
    </>
  )
}

function App() {
  const [settings, setSettings] = React.useContext(settingsContext);
  let history = useHistory();
  let is500 = (history.location.pathname.indexOf("/500") > -1 ? true : false);
  useEffectOnce(initialize);
  usePageViewLogging();
  //useReloadOnVersionUpdate();
  usePicturefill();
  
  async function initialize() {
    let lastInitializedDate = new Date();
    let refreshWindow = minutesToMilliseconds(4);

    updateSettings();
    setInterval(updateSettingsIfNeccessary, minutesToMilliseconds(0.25));

    /**
     * Periodically update the settings, to prevent the app from getting
     * stale should the server code be updated. The useReloadOnVersionUpdate
     * will handle reloading the application if the app version does not
     * match the server version.
     */
    function updateSettingsIfNeccessary() {
      let refreshAfterDate = new Date(lastInitializedDate.getTime() + refreshWindow);
      let dateNow = new Date();

      if (dateNow > refreshAfterDate) {
        updateSettings();
        lastInitializedDate = dateNow;
      }
    }

    function minutesToMilliseconds(minutes) {
      return minutes * 60000;
    }

    async function updateSettings() {
      let result = await settingsService.getSettings();      

      // If user is logged in, we will use the country value provided by the API
      // Otherwise, we'll check sessionStorage
      let sessionCountry = sessionStorage.getItem('philadelphiaBrunch.country');
      if (!result.country && sessionCountry) {
        result.country = sessionCountry;
      }

      if (!sessionCountry && result.country) {
        sessionStorage.setItem('philadelphiaBrunch.country', result.country);
      }

      // Update settings
      setSettings(result);

      // If user is logged in, go get their account
      if (result.isLoggedIn) {
        accountService
          .getAccountDetails()
          .then((response) => {
            let account = response.accountDetails;
            if (account) {
              setSettings({ ...result, account });
            }
          })
      }
    }
  }

  let isOpenOrMopUp = !settings.isLoading && (settings.competitionStatus.openState === "Open" || settings.competitionStatus.openState === "MopUp");

  return (
    <ErrorBoundary is500={is500}>
      <SiteLoader>
        <Header 
          enableMenu={isOpenOrMopUp}
          onLogout={() => window.location.reload()}
        />
        <Wrapper isReady={!settings.isLoading} showTerms={isOpenOrMopUp}>
          <CompetitionEntryProvider>
              <Routes />
          </CompetitionEntryProvider>
        </Wrapper>
      </SiteLoader>
    </ErrorBoundary>
  );
}

export default App;
