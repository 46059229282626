import React from 'react';
import { Helmet } from "react-helmet";
import { useRef } from 'react';
import useEffectOnce from 'hooks/useEffectOnce';
import { useHistory } from 'react-router-dom';
import './Policies.scss';

export default (props) => {
  const history = useHistory();
  const clause7 = useRef(null);

  useEffectOnce(() => {
    if (history.location.hash.indexOf("clause-7") > -1) {
      const section = document.querySelector('#clause-7');
      if (section !== null) { section.scrollIntoView({ behavior: 'smooth', block: 'nearest' }); }
    }

    if (history.location.hash.indexOf("products-list") > -1) {
      const section2 = document.querySelector('#products-list');
      if (section2 !== null) { section2.scrollIntoView({ behavior: 'smooth', block: 'center' }); }
    }
  });

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: 'smooth'
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Terms & Conditions | Philadelphia - Win a VIP brunch</title>
    </Helmet>
    <section className="policies-cont">
      <div className="layout-general layout-wrapper">
        <div className="layout-container layout-container--medium layout-container--center">
          <div className='policies'>
            <h1 className="type-h1">T&C<span>s</span></h1>
            <div className='policies__content'>
              <br />
              <ol>
                <li>
                  <p>This Promotion is open to residents of the United Kingdom, including Channel Islands and Isle of Man, and the Republic of Ireland aged 18 or over, excluding employees of the Promoter, its agents and anyone professionally associated with this Promotion.</p>
                </li>

                <li>
                  <p>Purchase is necessary. <strong>Please retain an itemised receipt that states the time and date of purchase prior to entry, but within the Promotion Period, as this may be required for validation and in order for the winner to receive their Prize.</strong> Internet access and a valid email address are required.</p>
                </li>

                <li>
                  <p>
                    Please note that while we want to encourage you to participate and enjoy the benefits of the Promotion we believe in responsible consumption of our products. DO NOT purchase more product than you can reasonably use or consume just to participate in this Promotion.
                  </p>
                </li>

                <li>
                  <p><strong>Promotion Period:</strong> Enter between 10:00 BST on the 1 July 2024 and 17:00 BST on the 31 August 2024 inclusive.</p>
                </li>

                <li >
                  <p> <strong>To Enter:</strong> </p>
                  <ol type='a'>
                    <li><p><strong>UK:</strong> Purchase a Promotional Pack (see <button type="button" className="button button--text-link" onClick={() => scrollToSection(clause7)}>clause 7</button> for list), scan the QR code or visit <a href="https://philadelphia-brunch.com" target="_blank" rel="noopener noreferrer" className='link'>philadelphia-brunch.com</a> and complete the online form with your details (full name, email address and post code) alongside the barcode and batch code to be entered into the Prize Draw.</p></li>
                    <li><p><strong>ROI:</strong> Visit <a href="https://philadelphia-brunch.com" target="_blank" rel="noopener noreferrer" className='link'>philadelphia-brunch.com</a> and complete the online form with your details (full name, email address and eir code) to be entered into the Prize Draw. </p></li>
                  </ol>
                 
                </li>

                <li>
                  <p>
                    <strong>To Claim:</strong> Upon entering the Promotion, each entrant will receive 1 x Philadelphia recipe PDF. Recipients should download the PDF to access the recipes before the end of the Promotion Period. The PDF will include recipes for:
                  </p>
                  <ol type='a'>
                    <li><p>Whipped Green Seasoning Philadelphia, Crispy Roast Smashed Turmeric Potatoes & Spiced Season Salt (recipe by Andi Oliver);</p></li>
                    <li><p>Philadelphia & Peach Prosciutto Flatbreads;</p></li>
                    <li><p>Philadelphia Brunch Frittatas;</p></li>
                    <li><p>Philadelphia & Smoked Salmon Bagel; and</p></li>
                    <li><p>Philadelphia Egg Brunch.</p></li>
                  </ol>
                </li>

                <li id="products-list" ref={clause7}>
                  <p>
                    <strong>Promotional Packs:</strong>
                  </p>
                  <ol type="a">
                    <li><p>Philadelphia Original Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Original Soft Cream Cheese 280G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese 280G</p></li>
                    <li><p>Philadelphia Original Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Original Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Original Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Original Soft Cream Cheese 260G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese 260G</p></li>
                    <li><p>Philadelphia Lightest Low Fat Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Lightest Herbs Low Fat Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Lactose Free Soft Cheese 150g</p></li>
                    <li><p>Philadelphia Garlic & Herbs Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Garlic & Herbs Soft Cream Cheese 280G</p></li>
                    <li><p>Philadelphia Chives Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Sweet Chilli Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Salmon Soft Cream Cheese 165G</p></li>
                    <li><p>Philadelphia Intense Herbs de Provence Soft Cream Cheese 140G</p></li>
                    <li><p>Philadelphia Intense Garlic & Herbs Soft Cream Cheese 140G</p></li>
                    <li><p>Philadelphia Vegan Almond & Oat Soft Cream Cheese Alternative 150G</p></li>
                    <li><p>Philadelphia Original Soft Cream Cheese Mini Tub 4 Pack 140G</p></li>
                    <li><p>Philadelphia Light Soft Cream Cheese Mini Tub 4 Pack 140G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese Snacks 3 Pack 126G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese Snacks 42G</p></li>
                    <li><p>Philadelphia Light Low Fat Soft Cream Cheese Snacks 42G</p></li>
                    <li><p>Philadelphia Snack Light & Herbs Soft Cheese Dip & Wholegrain Breadsticks </p></li>
                    <li><p>Philadelphia Milka Soft Cheese 150G</p></li>
                  </ol>
                </li>

                <li>
                  <p>
                    Maximum of 1 entry is permitted per person per day, a maximum of 20 entries throughout the Promotion Period. One itemised receipt per entry receipt must state the time and date of purchase, which must pre-date the entry date and time, but be within the Promotion Period. No person may win more than 1 Prize type.
                  </p>
                </li>

                <li>
                  <p><strong>The Prizes:</strong></p>
                  <ol type='a'>
                    <li>
                      <p><strong>Tier 1:</strong> There will be 1 winner. The winner will win 1 x VIP Brunch Out Experience for the winner and 5 guests, including:</p>
                      <ol type='i'>
                        <li>
                          <p><strong>Transport:</strong></p>
                          <ol type="1">
                            <li><p>For GB: Return first class train tickets from the winner’s local train station to London for the winner and 5 guests. If the winner lives in or in close proximity to London, as determined by the Promoter, they will be sent a private car.</p></li>
                            <li><p>For NI: Return business class flights from Belfast to London alongside return train tickets from the winner’s local train station to Belfast for the winner and 5 guests.</p></li>
                            <li><p>For ROI: Return business class flights from Dublin to London alongside return train tickets from the winner’s local train station to Dublin for the winner and 5 guests.</p></li>
                            <li><p>For CI & IOM: Return ferry tickets to mainland GB alongside return first class train tickets from the ferry terminal to London for the winner and 5 guests.</p></li>
                          </ol>
                          <p>If the stated transport is not available at the time, the winner will be offered a substitute of equal value.</p>
                        </li>
                        <li><p>3 x standard twin rooms in a 5* hotel in central London for one night for the winner and 5 guests, subject to availability and the Promoter’s discretion;</p></li>
                        <li><p>1 x All inclusive VIP Brunch at a central London location accompanied by Andi Oliver; and</p></li>
                        <li><p>1 x £300 spending money (£50 each for the winner and their 5 guests).</p></li>
                      </ol>
                    </li>
                    <li>
                      <p><strong>Tier 2:</strong> There will be a total of 55 winners. Each winner will win 1 x Brunch at Home Bundle for the winner and 3 guests, valued at £250. Exact contents are subject to availability and the Promoter’s discretion. The Bundle will consist of:</p>
                      <ol type='i'>
                        <li><p>1 x Glassware and Crockery hamper including porcelain plates and bowls and China glassware teacups and saucers;</p></li>
                        <li><p>1 x Premium Breakfast Food hamper including tea, coffee, pastries, baked bread, cereal, granola and the ingredients to make an English breakfast; and</p></li>
                        <li><p>1 x Philadelphia selection pack including Philadelphia Original, Garlic & Herb, Salmon, and Chive.</p></li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <p>
                    <strong>Further Prize Details and Conditions:</strong>
                  </p>
                  <ol type="a">
                    <li>
                      <p>Tier 1:</p>
                      <ol type="i">
                        <li><p>At least 2 guests must be aged 18+. Up to 3 guests may be minors, however they should be accompanied by a responsible adult(s) and with their guardian’s consent.</p></li>
                        <li><p>The winner must provide their date preference via email to <a href="mailto:philadelphia-brunch@promowinners.com" target="_blank" rel="noopener noreferrer" className='link'>philadelphia-brunch@promowinners.com</a> a minimum of 4 weeks in advance. The exact Prize details, including the brunch with Andi Oliver, will be arranged between the winner and the Prize fulfilment agency during winner communications. The Prize fulfilment agency will make all reasonable efforts to provide the winner with details of the Prize at the earliest possible opportunity. The Promoter will have final discretion on all elements and aspects of how the Prize is organised. Once the Prize details including dates, locations and activities are confirmed, this cannot be amended or altered by the winner.</p></li>
                        <li><p>The Prize cannot be taken over a public holiday.</p></li>
                        <li><p>The winner and their guests must travel together in both directions.</p></li>
                        <li><p>Once tickets have been issued, they are only valid for the train/flight dates and times shown. No alternatives are available.</p></li>
                        <li><p>The winner and their guest must abide by and are subject to the train line’s/airline’s published Conditions of Carriage.</p></li>
                        <li>
                          <p>ROI only:</p>
                          <ol type='1'>
                            <li><p>The winner and their guest will require a passport valid for at least 6 months on date of travel; travel insurance is the responsibility of the winner and their guest. In the event the winner or their guest are unable to travel due to visa or other personal circumstances, no alternative tickets will be issued, and no compensation or other prize will be provided.</p></li>
                            <li>
                              <p>The Prize will be forfeited if:</p>
                              <ol type="a">
                                <li><p>the winner has not obtained all of the proper travel documents by the date specified by the Promoter;</p></li>
                                <li><p>the winner has any immigration complications which prevent them from entering, leaving or travelling within the UK or the Republic of Ireland; or</p></li>
                                <li><p>the winner has any medical, legal or any other reason that would prevent you from accepting and participating in the Prize.</p></li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li><p>All elements of the Prize package must be taken as part of the same trip.</p></li>
                        <li><p>The Promoter will pay the cost of the standard rooms plus related room taxes.</p></li>
                        <li><p>A valid credit or debit card will be required to check in to your rooms and will be used to guarantee any incidentals such as in- room calls or services.</p></li>
                        <li><p>The winner is solely responsible for any additional charges incurred at the hotel during their stay, including, without limitation, costs of meals, drinks, additional nights and services together with related taxes.</p></li>
                        <li><p>The winner must inform the Promoter of any wheelchair or any similar access needs.</p></li>
                        <li><p>The winner must inform the Promoter of any dietary requirements or allergens by 2 weeks prior to receiving the Prize.</p></li>
                        <li><p>The winner will not be entitled to any compensation and will have no claim against the organisers if the holiday or any other event forming part of the Prize is delayed, postponed, rescheduled or cancelled.</p></li>
                        <li><p>For the avoidance of doubt, the Prize does not include transportation, food, beverages, souvenirs, gratuities, car parking charges, excess baggage, additional excursions and attractions or any other costs of a personal nature (including spending money) that are not explicitly set out in these Terms and Conditions and neither the Promoter nor any provider of any part of the Prize will be responsible for any such costs.</p></li>
                        <li><p>The winner is responsible for the behaviour of themselves and their guests whilst taking the Prize. The Promoter reserves the right in its absolute discretion to exclude the winner and/or their guest from participation in any aspect of the Prize if any party fails to comply with the directions of the Promoter or any companies associated with the Prize or if the winner and/or their guest acts in a manner that is dangerous to themselves or to the public or is, in the opinion of the Promoter, anti-social in any manner or which causes a disturbance or nuisance to others. Failure to comply with these Terms and Conditions may result in refused entry, or the ticket holders being required to leave the event/venue.</p></li>
                      </ol>
                    </li>
                    <li>
                      <p>Tier 2:</p>
                      <ol type="i">
                        <li><p>The winners will receive the Bundle via post. </p></li>
                        <li><p>Exact contents of the hampers are subject to availability. In the event that a planned hamper is not available at the time of Prize fulfilment, an alternative hamper of the same value will be provided.</p></li>
                        <li><p>It is the responsibility of the participant to provide their correct, up-to-date details when entering the Promotion and/or confirming acceptance of the Prize in order for their Prize to be processed. The Promoter cannot be held responsible for winners failing to supply accurate information which affects Prize acceptance or delivery of their Prize.</p></li>
                        <li><p>The Promoter is not liable for any Prizes once distributed/dispatched to a winner and the Promoter will not be responsible for any Prizes not received, broken or lost in transit.</p></li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <p><strong>Winner Selection:</strong> 1 Tier 1 provisional winner and 55 Tier 2 provisional winners (“Provisional Winners”) will be randomly selected from all valid entries received during the Promotion Period. For your total confidence, the Prize draw will be conducted by PromoVeritas, the independent promotional verification service, within 5 working days of the end of the Promotion Period.</p>
                </li>

                <li>
                  <p><strong>Winner Notification:</strong> Provisional Winners will be contacted via the email address provided on entry within 5 working days of Winner Selection and will be asked to provide evidence of identity and eligibility. Reasonable efforts will be made to contact the Provisional Winners. Once eligibility has been confirmed, the Provisional Winners will be confirmed as a winner. If a provisional winner does not respond to the initial contact within 14 days, we reserve the right to disqualify that entrant and award the Prize to a reserve selected in the same manner. Reserve winners may have less time to respond.</p>
                </li>

                <li>
                  <p><strong>Prize Acceptance:</strong> Notification to arrange fulfilment of the Prizes will take place within 28 days of acceptance of the Prizes. In the unlikely event that a winner has not received communication to arrange their Prize the winner must inform the Promoter by emailing <a href="mailto:philadelphia-brunch@promowinners.com" target="_blank" rel="noopener noreferrer" className='link'>philadelphia-brunch@promowinners.com</a>. If a winner does not do so, the Promoter reserves the right to not reissue the Prize or limit its value at its sole discretion.
                  </p>
                </li>

                <li>
                  <p>Provided no objection is received from the winners, a winners list containing the surname and county of residence of the winners will be made available by the Promoter 4 weeks after the close of the Promotion Period, for a period of 8 weeks. It will be available by sending a request via email to <a href="mailto:philadelphia-brunch@promowinners.com" target="_blank" rel="noopener noreferrer" className='link'>philadelphia-brunch@promowinners.com</a>. The winners can object to their details being published by emailing <a href="mailto:philadelphia-brunch@promowinners.com" target="_blank" rel="noopener noreferrer" className='link'>philadelphia-brunch@promowinners.com</a>. Without prejudice, the Promoter will provide winner information to the Advertising Standards Authority when requested by them.
                  </p>
                </li>

                <li>
                  <p>
                    The Promoter may request that the winners participate in publicity arising from the Promotion. Participation is at the winner’s discretion and is not a condition of Prize Acceptance.
                  </p>
                </li>

                <li>
                  <p>
                    The Promoter seeks to run a fair and secure promotion and prevent abuse and cheating. If you enter in a way that is not consistent with these Terms & Conditions, your entry/ies (and any of your associated aliases) will be disqualified. Any Prize awarded may be void and recoverable, and the Promoter reserves the right to bar you from entering future promotions for a period of at least six months. For this reason, the Promoter reserves the right at any point to:
                  </p>
                  <ol type="a">
                    <li><p>Verify the eligibility of entrants and/or Provisional Winners through multiple means by requesting such information it consider reasonably necessary for this purpose. Entry or a Prize may be withheld until verification is completed.</p></li>
                    <li><p>Disqualify entries that are not made directly by the individual entering the Promotion. </p></li>
                    <li><p>Disqualify entries made using anonymous email services such as, but not limited to, GuerillaMail, Dispostable or Mailinator.</p></li>
                    <li><p>Disqualify bulk entries from individuals, trade, consumer groups or third parties, incomplete entries and entries submitted by macros or other automated means.</p></li>
                    <li><p>Disqualify entries beyond the maximum allowed, or those using techniques such as ‘script’, ‘brute force’, masking identity by manipulating IP addresses, using aliases or identities other than their own or any other means.</p></li>
                    <li><p>Disqualify entrants who tamper with the entry process.</p></li>
                    <li><p>Disqualify entries which, in some other way, do not meet the requirements of these Terms & Conditions. </p></li>
                    <li><p>Cancel, modify or suspend the Promotion should it not be capable of running as planned for reasons including but not limited to tampering, unauthorised intervention, fraud, dishonesty, technical failures, or any other causes beyond the control of the Promoter which corrupt or affect the administration, security, fairness, integrity or proper conduct of this Promotion.</p></li>
                  </ol>
                </li>

                <li>
                  <p>
                    The Prizes are not transferable or exchangeable and cannot be redeemed for monetary value or for any other form of compensation. If for any reason a Prize and/or any element of a Prize is not available, the Promoter reserves the right, at its sole discretion to substitute that Prize or element of the Prize for another of equal or greater value.
                  </p>
                </li>

                <li>
                  <p>
                    No responsibility can be taken for entries which are lost, delayed, corrupted, damaged, misdirected or incomplete or which cannot be delivered for any technical, delivery or other reason. Proof of sending will not be accepted as proof of receipt. The Promoter does not guarantee continuous or secure access to Philadelphia-brunch.com.
                  </p>
                </li>


                <li>
                  <p>
                    The Promoter and its associated agencies and companies are not liable for any loss (including, without limitation, indirect, special or consequential loss or loss of profits), expense or damage which is suffered or sustained (whether or not arising from any person’s negligence) in connection with this Promotion (JS14463) or accepting or using the/a Prize(s), except for any liability which cannot be excluded by law.  Nothing will exclude the Promotor’s liability for death or personal injury as a result of its negligence.
                  </p>
                </li>


                <li>
                  <p>
                    The Promoter will not be liable for any delay or failure to comply with its obligations for reasons beyond its reasonable control arising from but not limited to Acts of God, global or regional epidemic or pandemic, adverse weather conditions, fire, industrial dispute, war, terrorist activity, hostilities, political unrest, riots, civil commotion, plague or other natural calamities, or any other circumstances.
                  </p>
                </li>


                <li>
                  <p>
                    The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters’ Data Privacy Notice which can be found at <a href="https://eu.mondelezinternational.com/privacy-notice" target="_blank" rel="noopener noreferrer" className='link'>https://eu.mondelezinternational.com/privacy-notice</a>. The Promoter may disclose entrants’ personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants.
                  </p>
                </li>

                <li>
                  <p>
                    If any part/s of these Terms & Conditions are legally ruled to be invalid, illegal or unenforceable, the remainder will not be affected and will continue in full force and effect.
                  </p>
                </li>

                <li>
                  <p>
                    By entering, you will be deemed to have agreed to be bound by these Terms & Conditions.
                  </p>
                </li>

                <li>
                  <p>
                    If there is a discrepancy between these Terms & Conditions and those in any promotional material, these Terms & Conditions will prevail.
                  </p>
                </li>

                <li>
                  <p>
                    These Terms & Conditions are governed by the laws of England and Wales, and Irish law and their interpretation and application will be subject to the non-exclusive jurisdiction of the courts of England and Wales, and the Republic of Ireland. Residents of Scotland and Northern Ireland may bring proceedings in their own jurisdiction.
                  </p>
                </li>
              </ol>
              <p><strong>Promoter:</strong> Mondelez Europe Services GmbH – UK Branch with registered office at Cadbury House, Sanderson Road Uxbridge, UB8 1DH.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>;
}