import React from 'react';
import classList from 'react-classlist-helper';
//import './SummaryTsandCs.scss';

const SummaryTsandCs = (props) => {
  const classMap = {
    "layout-wrapper": true,
    [props.className]: !!props.className,
  };

  return (
    <section className={`${classList(classMap)}`} >
      <div className="layout-container layout-container--center layout-container--bottom-space">
        <div className='form__privacy'>
        <div className='sumtsandcs'>
            <div className='title'>Summary Terms & Conditions:</div>
            <p>UK & ROI, 18+ only. 10:00 01/07/24 - 17:00 31/08/24 ('Promotional Period'). During the Promotional Period, purchase a Promotional Pack (UK only), scan the QR code or visit philadelphia-brunch.com & complete the online form with your details alongside the barcode and batch code (UK only) to enter the Prize Draw. UK: Purchase necessary. ROI: No purchase necessary. Prizes: Tier 1: 1 x VIP Brunch Out Experience for the winner and 5 guests incl. transport, 1 night accommodation & £50 spending money each. Tier 2: 1 of 55 x Brunch at Home Bundles for the winner and 3 guests (worth £250 each). Gift: 1 x Philadelphia recipe PDF. Retain receipt. Internet access & valid email address required. Max 1 entry per person per day, max 20 in the Promotional Period. Max 1 Prize type per person. Full T&Cs & Prize details: <a href="/terms-and-conditions" target='_blank' rel='noopener noreferrer'>philadelphia-brunch.com/terms-and-conditions</a>. <strong>Promoter:</strong> Mondelez Europe Services GmbH - UK Branch, Cadbury House, Sanderson Road Uxbridge, UB8 1DH.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SummaryTsandCs;
