import React, { useEffect } from 'react';
import classList from 'react-classlist-helper';
import './PrizeBlock.scss';

const PrizeBlock = (props) => {

  const mainClassMap = {
    "prize-block": true
  };

  const imageClassMap = {
    "prize-block__image": true,
    "prize-block__image--contain": props.containImage
  };

  const classMap = {
    "show": props.show
  }

  return (
    (
      <section className={classList(mainClassMap)}>
        {(props.image && props.image2x) &&
          <div className={classList(imageClassMap)}>
            <picture>
              <img src={props.image} srcSet={props.image2x + " 2x, " + props.image + " 1x"} alt={props.alt || props.title} />
            </picture>
          </div>
        }

        <div className="prize-block__info">
          {props.title &&
            <h3 className="prize-block__title type-h3" dangerouslySetInnerHTML={{ __html: props.title }}></h3>
          }

          {
            props.description ?
              (<div className="prize-block__info-copy" dangerouslySetInnerHTML={{ __html: props.description }}></div>) : (<></>)
          }
        </div>
       
      </section>
    )
  )
}

export default PrizeBlock;
