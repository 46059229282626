import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useHistory } from 'react-router-dom';
import useEffectOnce from 'hooks/useEffectOnce';
import analyticsService from 'services/analyticsService';
import useSettingsContext from 'contexts/useSettingsContext';
import SettingsContext from 'contexts/SettingsContext';
import useCompetitionEntryContext from 'contexts/useCompetitionEntryContext';
import competitionEntryService from 'services/competitionEntryService';
import competitionEntryValidator from 'validators/competitionEntryValidator';
import accountService from 'services/accountService';

import Button from 'components/Button/Button';
import Checkbox from 'components/Forms/Checkbox';
import TextField from 'components/Forms/TextField';
import SelectField from 'components/Forms/SelectField';
import DateInput from 'components/Forms/DateInput';
import Link from 'components/Link/Link';

import ValidationMessage from 'components/Validation/ValidationMessage';
import ValidationSummary from 'components/Validation/ValidationSummary';
import ValidationErrorCollection from 'validators/ValidationErrorCollection';
import ReCaptcha from 'components/Forms/ReCaptcha';
import Modal from 'components/Modal/Modal';
import AlertIcon from 'assets/images/alert.svg';
import Form, { createOnChangeHandler } from './Form';

import './Form.scss';
import SummaryTsandCs from 'components/SummaryTsandCs/SummaryTsandCs';
import DownloadPDF from '../../assets/Philadelphia_Brunch_Recipes.pdf';
import TextFieldCharRestricted from 'components/Forms/TextFieldCharRestricted';

export default () => {
  let history = useHistory();
  let competitionEntryContext = useCompetitionEntryContext();
  let settingsContext = useSettingsContext();
  let compState = settingsContext.competitionStatus.openState;
  let compPhaseId = settingsContext.competitionStatus.currentPhasePublicId;
  let competitionIsOpen = compState === "Open";
  let minBirthDate = new Date();
  minBirthDate.setYear(minBirthDate.getFullYear() - 18);


  let [settings, setSettings] = React.useContext(SettingsContext);
  let [firstName, setFirstName] = useState('');
  let [email, setEmail] = useState('');
  let [emailConfirm, setEmailConfirm] = useState('');
  let [address1, setAddress1] = useState('');
  let [address2, setAddress2] = useState('');
  let [postcode, setPostcode] = useState('');
  let [accountOptIn, setAccountOptIn] = useState(false);
  let [terms, setTerms] = useState(false);
  let [legalAge, setLegalAge] = useState(false);
  let [dob, setDoB] = useState({
    isValid: false
  });
  let [optIn, setOptIn] = useState(false);
  let [teamOptIn, setTeamOptIn] = useState(false);
  let [validation, setValidation] = useState({
    errors: new ValidationErrorCollection(),
    acknowledged: false
  });

  let [recaptcha2, setRecaptcha2] = useState(null);

  let [showValidationModal, setShowValidationModal] = useState(false);

  let isIreland = settings.country === "ROI";

  const otherTeams = ["WLV", "NOT", "MCI", "WBA", "SHF", "BRR", "SHU", "LNC", "CDF"];



  useEffect(() => {

    if (validation.errors.hasErrors && !validation.acknowledged) {
      setShowValidationModal(true);
    } else {
      setShowValidationModal(false);
    }

  }, [validation])


  useEffect(() => {

    var sett_account = settings.account;
    if (sett_account === null || sett_account === undefined) {
      setDoB(null);
    } else {

      if (!sett_account.birthday) {
        setDoB(null);
      }
    }

  }, [optIn])

  const createCommand = () => {
    let model = {
      firstName,
      email,
      emailConfirm,
      country: settings.country,
      dob,
      address1,
      address2,
      postcode,
      terms,
      legalAge,
      barcode: competitionEntryContext.barcode,
      batchcode: competitionEntryContext.batchcode,
      tier1PrizePublicId: null,
      optIn,
      teamOptIn,
      recaptcha2,
      phaseId: compPhaseId
    };

    if (dob && dob.isValid) {
      Object.assign(model, {
        dateOfBirthDay: dob.date.getDate(),
        dateOfBirthMonth: dob.date.getMonth() + 1,
        dateOfBirthYear: dob.date.getFullYear(),
        dob
      })
    }

    return model;
  }

  var handleReCaptchaV2 = (val) => {
    if (val !== null && val !== "") {
      createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2, val)
    }
  }

  var handleReCaptchaV2Expired = () => {
    setRecaptcha2(null);
  }


  useEffectOnce(() => {
    window.setTimeout(function () {
      try {
        let $grecaptchav2 = document.querySelector('.g-recaptcha-v2');
        if ($grecaptchav2) {

          window.grecaptcha.ready(() => {
            window.grecaptcha.render($grecaptchav2, {
              'sitekey': '6LdOTuMpAAAAADcgK1IZAnTCeGy9KOEQ8hypU25P',
              'callback': handleReCaptchaV2,
              'expired-callback': handleReCaptchaV2Expired
            });
          });
        }
      } catch { }
    }, 1000);

  });

  const onFormSuccess = (result) => {

    analyticsService.logEvent("forms", "Forms", {
      action: "RegistrationSuccess"
    });

    // forward to thank-you page
    competitionEntryContext.reset();
    competitionEntryContext.setEmail(email);
    competitionEntryContext.setResult(result);

    // Update csfr token and flag as logged in
    setSettings({ ...settings, prize: "", isLoggedIn: settings.isLoggedIn ? true : accountOptIn });

    let urlData = `/${result.entryPublicId}`;
    //let urlData = !competitionIsMopup ? `/${result.entryPublicId}` : '';
    history.push(`/thankyou${urlData}`);
  }

  const onDismissModal = () => {
    setValidation(prevState => ({ ...prevState, acknowledged: true }));
    /* This has to be here to bypass hideClose */
    document.body.classList.remove("overflow-hidden-mediumdown");
  }

  /* RENDER */
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Register | Philadelphia - Win a VIP brunch</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">
      <div className="layout-container layout-container--slim layout-container--center layout-container--pb0">
        <h1 className="type-h1">Download recipes</h1>
        <div className="form__intro">
          <p>
            Before we take some more details from you to enter our VIP Brunch competition, here’s a little something for you… click below to download your FREE digital recipe book, full of Philly inspiration for brunch and beyond.
          </p>
          <Link
            analytics={{ evnt: "form", category: "RegistrationForm", action: "DownloadRecipieBook", value: "Download recipe book" }}
            to={DownloadPDF} download="Philadelphia_Brunch_Recipes" lozenge title="Download now" >Download now</Link>
        </div>
      </div>
    </section>

    <section className="layout layout-wrapper form-template">
      <div className="layout-container layout-container--slim layout-container--center layout-container--bottom-space layout-container--pb0">
        <h1 className="type-h1">Your details</h1>

        <div className="form__intro">
          <p >
            We just need some additional info from you to make sure you’re eligible to enter, and so we can contact you if you’re a lucky winner.
          </p>
          <p>
            Fields marked with a '*' are mandatory.
          </p>
        </div>
        <Form
          id="registration"
          submitTitle="Submit"
          onSuccess={onFormSuccess}
          formClass="form form--white form--slim"
          command={{
            model: createCommand,
            service: competitionEntryService.addCompetitionEntry
          }}
          validation={{
            validator: competitionEntryValidator,
            onValidation: setValidation
          }}
          submitAnalytics={[
            { evnt: "form", category: "RegistrationForm", action: "EmailCapture", label: email, value: "Registration form submission" },
            (dob && dob.isValid) &&
            { evnt: "form", category: "RegistrationForm", action: "DateOfBirth", label: dob.date }
          ]}
        >

          {validation.errors.hasErrors && validation.acknowledged &&
            <ValidationSummary
              errors={validation.errors}
              handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
            />
          }

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted
              label="* First name"
              id="firstName"
              onChange={createOnChangeHandler(validation, 'firstName', setFirstName)}
              value={firstName}
              hasErrors={!!validation.errors.firstName}
              maxCharLenght={60}
            />
            <ValidationMessage errors={validation.errors.firstName} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted
              label="* Email"
              id="email"
              type="text"
              autoComplete="off"
              onChange={createOnChangeHandler(validation, 'email', setEmail)}
              value={email}
              hasErrors={!!validation.errors.email}
              maxCharLenght={150}
            />
            <ValidationMessage errors={validation.errors.email} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextField
              label="* Confirm email"
              id="emailConfirm"
              autoComplete="off"
              type="text"
              onChange={createOnChangeHandler(validation, 'emailConfirm', setEmailConfirm)}
              value={emailConfirm}
              hasErrors={!!validation.errors.emailConfirm}
            />
            <ValidationMessage errors={validation.errors.emailConfirm} />
          </fieldset>

          <fieldset className="form__fieldset">
            <TextFieldCharRestricted
              label={settings.country !== "ROI" ? '* Postcode' : '* Eircode'}
              id="postcode"
              onChange={createOnChangeHandler(validation, 'postcode', setPostcode)}
              value={postcode}
              hasErrors={!!validation.errors.postcode}
              maxCharLenght={60}
            />
            <ValidationMessage errors={validation.errors.postcode} />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox
              id="legalAge"
              label="* I confirm I am over 18 years old."
              hasErrors={validation.errors.hasErrors && !legalAge}
              onChange={createOnChangeHandler(validation, 'legalAge', setLegalAge)}
              checkboxAnalytics={{ evnt: "form", category: "RegistrationForm", action: "CheckLegalAge" }}
            />
            <ValidationMessage errors={validation.errors.legalAge} />
          </fieldset>

          <fieldset className="form__fieldset">
            <Checkbox
              id="optIn"
              label="I would like to receive the latest news and updates from Philadelphia via email."
              onChange={setOptIn}
              value={optIn}
              checkboxAnalytics={{ evnt: "form", category: "RegistrationForm", action: "CheckOptIn" }}
            />

            {
              optIn &&
              <>
                <br />
                <div className='form__date-entry'>
                  <label
                    className="textfield__label textfield__label--dob"
                    htmlFor="">
                    If you would like a birthday offer in your inbox, tell us your date of birth (optional). We also use this information to personalise your future experiences with Philly.
                  </label>
                  <br />

                  <DateInput
                    className="date-select"
                    minDate={minBirthDate}
                    minDateErrorMessage="You must be over 18 to enter this promotion"
                    onChange={createOnChangeHandler(validation, 'dob', setDoB)}
                    value={dob}
                  />
                  <ValidationMessage errors={validation.errors.dateOfBirthDay || validation.errors.dob} />
                </div>
              </>
            }
          </fieldset>
          <fieldset className="form__fieldset">
            <Checkbox
              id="teamOptIn"
              label="Tick this box to grant Philadelphia with permissions to use your personal information to help us ensure our future communications with you, either through our partners or across third party platforms, are more relevant and tailored to you and the preferences you have shared. Philadelphia is an affiliate brand of Mondelez."
              onChange={setTeamOptIn}
              value={teamOptIn}
              checkboxAnalytics={{ evnt: "form", category: "RegistrationForm", action: "CheckThirdPartyOptIn" }}
            />

            <Checkbox
              id="terms"
              label="* I have read and agreed to the <a href='/terms-and-conditions' rel='noreferrer noopener' target='_blank'>T&Cs</a> of this promotion."
              hasErrors={validation.errors.hasErrors && !terms}
              onChange={createOnChangeHandler(validation, 'terms', setTerms)}
              checkboxAnalytics={{ evnt: "form", category: "RegistrationForm", action: "CheckTandCs" }}
            />
            <ValidationMessage errors={validation.errors.terms} />
          </fieldset>

          <p className='form__msg'>We value your trust when sharing your personal data with us. We always treat your data in a fair and respectful manner limited to the purpose above mentioned. If you would like to know more about how we handle your data, please read our Privacy Notice</p>

          <fieldset className="form__fieldset">
            <ReCaptcha captchaSettings={settingsContext.captchaSettings} />
            <div className="g-recaptcha-v2" data-sitekey="6LdOTuMpAAAAADcgK1IZAnTCeGy9KOEQ8hypU25P"
              data-load={createOnChangeHandler(validation, 'recaptcha2', setRecaptcha2)}></div>
            <ValidationMessage errors={validation.errors.recaptcha2} />
          </fieldset>

          {showValidationModal &&
            <Modal
              panel
              reducedPadd
              hideClose
              show={showValidationModal}
              icon={AlertIcon}
              alt="Warning sign"
              onDismiss={onDismissModal}
              onConfirm={onDismissModal}
              title="Oops">
              <ValidationSummary
                modal
                defaultMessage="Please check you have entered all fields correctly."
                errors={validation.errors}
                handlePropertyErrors={['entryData', 'RecaptchaToken', 'optIn']}
              />
              {!validation.errors.globalErrors.length ? (
                <Button title="Close" onClick={onDismissModal} />
              ) : (
                <Button title="Back to homepage" href="/" />
              )
              }
            </Modal>
          }

        </Form>

      </div>
    </section>

    <SummaryTsandCs />
  </>;
}